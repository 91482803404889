import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

// components
import { Modal } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
// @ts-expect-error file not in typescript
import LinkComponent from '../../../../atoms/Link'

// constants
import { PC_SUBMIT_WITH_DOWNLOAD_QUOTE } from '../../../../../constants/modal'
import { DOC_TYPE_ALTERATION_PC } from '../../../../../constants/documentTypes'
import { MAX_LIMIT_ERROR_CODE } from '../../../../../constants/premiumCalculator'

// types
import { Fields } from '../../../../../types/components/PremiumCalculatorSubmit'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'
import { Store } from '../../../../../types/store'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { downloadDocument } from '../../../../../utils/downloadDocumentUtils'
// @ts-expect-error file not in typescript
import { generateCorrelationID } from '../../../../../utils/commonUtils'
import { createEvent } from '../../../../../utils/telemetry'

// selectors
import {
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
  getAlterations,
} from '../../../../../selectors/common.selectors'

// styles
import styles from './pcSubmitModalWithDownloadQuote.styles'

const DownloadIcon = styled(Icon)(styles.downloadIcon)
const ButtonsContainer = styled('div')(styles.buttonsContainer)

const PCSubmitModalWithDownloadQuote = ({ fields }: { fields: Fields }) => {
  const dispatch = useDispatch()
  const modalState = useSelector((state: Store) => state.modal)
  const config = useSelector((state: Store) => state.config)
  const { activeIndex, quotes, isDownloadQuoteInProgress } = useSelector(getCreateQuote)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const alterationsState = useSelector(getAlterations)

  // modal close(X) button
  const handleClose = () => {
    dispatch(actionCreators.hideModal(PC_SUBMIT_WITH_DOWNLOAD_QUOTE))
  }

  // back to home click
  const handleBackToHomeClick = () => {
    const event = createEvent({
      GA: {
        category: 'PC-ROThankYoupopupBackToHome',
        action: 'Thank you popup Back to home	',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator thank you popup back to home',
        },
      },
    })
    event.end()
  }

  // download quote and leave
  const handleDownloadClick = useCallback(() => {
    const event = createEvent({
      GA: {
        category: 'PC-RODownloadQuotePopup',
        action: 'Thank you popup download quote',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator thank you popup download quote',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.updateDownloadQuoteProgress(true))
    downloadDocument(
      {
        docType: DOC_TYPE_ALTERATION_PC,
        quote: quotes[activeIndex],
        correlationID: generateCorrelationID(),
        lifeInsuredFirstName: firstName,
        lifeInsuredLastName: lastName,
        // modal will appear after successful submit, quote pdf already uploaded to RECM
        isUploadToRecm: false,
        returnData: true,
        quoteURN: alterationsState?.quoteURN,
      },
      null,
      null,
      config,
      (err: { status: { code: string } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(actionCreators.updateDownloadQuoteProgress(false))
        if (!err) {
          handleClose()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsAltsQuoteDowloaded())
        } else if (err.status?.code === MAX_LIMIT_ERROR_CODE) {
          handleClose()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsMaxDownloadLimitModal(true))
        }
      }
    )
  }, [quotes, alterationsState])
  const { BackToHomeLink } = fields
  return (
    <Modal
      isOpen={modalState[PC_SUBMIT_WITH_DOWNLOAD_QUOTE]?.isModalVisible}
      onClose={handleClose}
      title={fields.SubmitModalHeading}
      footer={
        <ButtonsContainer>
          <Button onClick={handleDownloadClick} isLoading={isDownloadQuoteInProgress}>
            <DownloadIcon iconName={['far', 'download']} />
            {renderTextField(fields.DownloadQuote)}
          </Button>
          <LinkComponent
            onClick={handleBackToHomeClick}
            variant="link"
            to={BackToHomeLink?.value?.href}
          >
            {BackToHomeLink?.value?.text}
          </LinkComponent>
        </ButtonsContainer>
      }
    >
      {renderTextField(fields.SubmitModalWithDownloadQuoteDetails, true)}
    </Modal>
  )
}

export default PCSubmitModalWithDownloadQuote
