import { Variables } from '@mlcl-digital/mlcl-design'
import type { CSSObject } from '@emotion/styled'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { space, colours, fontFamily, fontWeights, fontSize, mediaQueries } =
  Variables as VariablesType

const messageStyle: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  padding: `${space(2, true)} ${space(3, true)}`,
  color: colours.functionalDangerDark,
  svg: {
    fontSize: fontSize.md,
    marginRight: space(1),
  },
  span: {
    paddingLeft: space(1),
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
  },
}

const styles: Record<string, CSSObject> = {
  header: {
    h3: {
      display: 'flex',
      fontFamily: fontFamily.sourceSans,
      borderTop: 0,
      marginBottom: 0,
      div: {
        fontWeight: fontWeights.light,
        margin: `${space(0.5, true)} ${space(0.5, true)}`,
        alignItems: 'center',
        [mediaQueries.sm]: {
          margin: `${space(0.5, true)} ${space(1, true)}`,
        },
      },
    },
  },
  policyDetails: {
    display: 'flex',
    marginTop: space(1),
    span: {
      color: colours.tertiaryOne,
      '&:first-of-type': {
        fontWeight: fontWeights.semiBold,
        marginRight: space(1, true),
      },
    },
  },
  optOutSection: {
    padding: space(3),
    borderBottom: `1px solid ${colours.tertiaryFour}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(2),
    textAlign: 'center',
    span: {
      color: colours.tertiaryOne,
      '&:first-of-type': {
        marginLeft: space(1, true),
        fontWeight: fontWeights.semiBold,
      },
    },
    [mediaQueries.sm]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'unset',
    },
  },
  linkedPolicyList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(0.5),
    svg: {
      marginRight: space(1),
    },
    [mediaQueries.sm]: {
      alignItems: 'flex-start',
    },
  },
  body: {
    padding: 0,
  },
  footer: {
    color: colours.tertiaryOne,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSize.sm,
  },
  equalSplitDivs: {
    display: 'flex',
    flexDirection: 'column',
    '> div': {
      flex: '1 1 0px',
      padding: space(3),
      borderBottom: `1px solid ${colours.tertiaryFour}`,
      '&:last-of-type': {
        borderBottom: 'unset',
      },
    },
    [mediaQueries.sm]: {
      flexDirection: 'row',
      '> div': {
        borderBottom: 'unset',
        borderRight: `1px solid ${colours.tertiaryFour}`,
        '&:last-of-type': {
          borderRight: 'unset',
        },
      },
    },
  },
  coverSection: {
    h3: {
      fontFamily: fontFamily.sourceSans,
      marginBottom: space(4),
    },
    dl: {
      display: 'flex',
      color: colours.tertiaryOne,
      dt: {
        fontWeight: fontWeights.semiBold,
        marginRight: space(2),
      },
      dd: {
        '> div': {
          marginLeft: space(1),
        },
      },
    },
  },
  priceComparison: {
    color: colours.tertiaryOne,
    '> div': {
      '&:nth-child(2)': {
        dd: {
          color: colours.mlcAquaAccessible,
        },
      },
      dd: {
        fontWeight: fontWeights.bold,
        fontSize: fontSize.md,
      },
    },
  },
  benefitName: {
    margin: `${space(2, true)} 0`,
    fontFamily: fontFamily.sourceSans,
    svg: {
      marginRight: space(1),
    },
  },
  premiumSaved: {
    background: colours.mlcAquaAccessible,
    color: colours.tertiarySix,
    padding: space(3),
    '& >div:last-of-type': {
      fontSize: fontSize.md,
    },
  },
  errorMessage: {
    ...messageStyle,
    background: colours.functionalWarningLight,
  },
  premiumIncreasedMessage: {
    ...messageStyle,
    background: colours.functionalDangerLight,
  },
  radioGroup: {
    base: {
      margin: 0,
      '> div': {
        padding: `0 ${space(1, true)} 0 ${space(2, true)}`,
      },
    },
  },
}

export default styles
