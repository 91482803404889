import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { Card as LoginWrapperCard, Heading, Button, Icons } from '@mlcl-digital/mlcl-design'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../../../actions/types/authentication'

import { Store } from '../../../types/store'
import { SitecoreFields } from './welcomePage.types'
import { SitecoreField } from '../../../types/sitecore'
import { ADVISOR_PORTAL } from '../../../constants/site'

import { themeConsumer } from '../../../styles/ThemeContext'
import styles from './welcomePage.styles'

// Not sure why the ESlint disables are required are
// and think it's an error with the linter and not the file
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Logo from '../../atoms/MLCAcendaLogo'
import Login from './components/LogInButton'
import ForgottenUserNameForm from './components/ForgotUserNameForm'
import ForgotUserNameConfirmation from './components/ForgotUserNameConfirmation'

// utils
import { createEvent } from '../../../utils/telemetry'
// @ts-expect-error non-TS code
import { isFeatureEnabled } from '../../../utils/featureToggling'
import {
  getThirdPartyQuoteProgressMsg,
  getShowThirdPartyLoading,
  // @ts-expect-error non-TS code
} from '../../../utils/loginPageUtils'
// @ts-expect-error non-ts code
import history from '../../../utils/browserHistory'
import ThirdPartyLoading from '../../molecules/ThirdPartyLoading'

const CenterBox = styled('div')(styles.base)
const BackgroundDetail = styled('div')(styles.backgroundDetail)
const HeaderOuterContent = styled('div')(styles.headerOuterContent)
const LoginLinkWrapper = styled('div')(styles.loginWrapper)
const DescriptionWrapper = styled('div')(styles.descriptionWrapper)
const NavButton = styled(Button)(styles.nav)

const { IconChevronLeft16 } = Icons as { [key: string]: React.ReactNode }

type Props = {
  theme: { bg: string }
  logoHorizontal: string
  logoVertical: string
  isForgotUserNamePage?: boolean
  fields: SitecoreFields
  location: URL
}

type ComponentSchema = {
  component: React.ReactNode
  heading?: SitecoreField
  description?: SitecoreField
  handleBack?: () => void
}

const WelcomePage = (props: Props) => {
  const { theme, logoHorizontal, logoVertical, isForgotUserNamePage, fields, location } = props
  const dispatch = useDispatch()
  const [component, setComponent] = useState('login')

  const { loginLinkTooltip, loginLabel, logoAltText } = fields

  const { SITE, MLCL_CUSTOMER_APP_PATH, MLCL_ADVISOR_APP_PATH } = useSelector(
    (state: Store) => state.config
  )
  const isLoading = useSelector((state: Store) => state.authentication.isLoading)
  const appLoginPath = SITE === ADVISOR_PORTAL ? MLCL_CUSTOMER_APP_PATH : MLCL_ADVISOR_APP_PATH

  const handleConfirmation = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'Forgot customer number',
        action: 'Back to login',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Forgot customer number - Back to login',
        },
      },
    })
    tagEvent.end()
    setComponent('forgotUserNameConfirmation')
  }

  // TODO: Possible clean up
  const handleLoadForgottenUsernameForm = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'CP - Forgot Customer number',
        action: 'Forgot Customer number',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Forgot customer number - Splash Screen - Forgot username',
        },
      },
    })
    tagEvent.end()
    setComponent('forgotUserNameForm')
  }

  const redirectToOktaWidget = () => {
    // @ts-expect-error will resolve this, not sure if attaching this to the window is a good idea
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.authClient.token.getWithRedirect({
      responseType: ['token'],
      state: `${window.location.search}${window.location.hash}` || undefined,
    })
  }

  const components: { [index: string]: ComponentSchema } = {
    login: {
      component: (
        <Login handleLoadForgottenUsernameForm={handleLoadForgottenUsernameForm} fields={fields} />
      ),
      heading: fields.heading,
    },
    forgotUserNameForm: {
      component: <ForgottenUserNameForm fields={fields} handleConfirmation={handleConfirmation} />,
      heading: fields.forgotUsernameTitle,
      description: fields.forgotUsernameSubtitle,
      handleBack: () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        history.replace('/')
      },
    },
    forgotUserNameConfirmation: {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      component: <ForgotUserNameConfirmation fields={fields} goBack={() => history.replace('/')} />,
      heading: fields.forgotUsernameTitle,
    },
  }

  // Get third party params
  const thirdParty = useSelector((state: Store) => state.thirdParty)
  const productRules = useSelector((state: Store) => state.productRules)
  const masterList = useSelector((state: Store) => state.masterList)
  const thirdPartyQuoteProgressMsg = getThirdPartyQuoteProgressMsg({
    thirdParty,
    productRules,
    masterList,
    fields,
  })
  const showThirdPartyLoading = getShowThirdPartyLoading({
    location,
    thirdPartyQuoteProgressMsg,
  })

  const thirdPartyLoading = useMemo(
    () =>
      thirdPartyQuoteProgressMsg && (
        <ThirdPartyLoading
          logoHorizontal={logoHorizontal}
          logoVertical={logoVertical}
          logoAltText={logoAltText?.value}
          message={thirdPartyQuoteProgressMsg}
        />
      ),
    [logoHorizontal, logoVertical, logoAltText]
  )

  // @ts-expect-error not defined
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const features = useSelector(state => state.config.FEATURES)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const isSplashScreenDisabled = isFeatureEnabled('RemoveSplashScreen', features)

  useEffect(() => {
    dispatch({ type: OKTA_RESET_AUTHENTICATION_STATE })
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (isSplashScreenDisabled) {
      if (!isForgotUserNamePage && !showThirdPartyLoading) {
        redirectToOktaWidget()
      } else if (isForgotUserNamePage) {
        setComponent('forgotUserNameForm')
      }
    }
  }, [])

  // Show an empty page for login only
  const showEmptyLoginPage =
    isSplashScreenDisabled && !isForgotUserNamePage && !showThirdPartyLoading
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    showEmptyLoginPage ? (
      <></>
    ) : (
      <>
        <CenterBox>
          {showThirdPartyLoading ? (
            thirdPartyLoading
          ) : (
            <LoginWrapperCard
              styleOverrides={{
                base: styles.cardBase,
                header: styles.header,
                footer: styles.footer,
                body: styles.body,
              }}
              header={
                <>
                  <HeaderOuterContent>
                    {components[component].handleBack && (
                      <NavButton
                        disabled={isLoading}
                        data-testid="backButton"
                        onClick={components[component].handleBack}
                        variant="tertiary"
                      >
                        {/* @ts-expect-error non-TS-code  */}
                        <IconChevronLeft16 />
                        <Text field={fields.backButtonLabel || {}} />
                      </NavButton>
                    )}
                  </HeaderOuterContent>
                  <Logo
                    href="/"
                    horizontalSrc={logoHorizontal}
                    verticalSrc={logoVertical}
                    alt={logoAltText?.value}
                  />
                  <HeaderOuterContent />
                </>
              }
              footer={
                <LoginLinkWrapper>
                  <Text field={loginLinkTooltip} />
                  <a href={appLoginPath} target="__blank">
                    <Text field={loginLabel} />
                  </a>
                </LoginLinkWrapper>
              }
            >
              {
                <>
                  {components[component].heading && (
                    <Heading variant="h2">
                      <RichText field={components[component].heading || {}} />
                    </Heading>
                  )}
                  {components[component].description && (
                    <DescriptionWrapper>
                      <Text field={components[component].description || {}} />
                    </DescriptionWrapper>
                  )}
                </>
              }
              {components[component].component}
            </LoginWrapperCard>
          )}
        </CenterBox>
        <BackgroundDetail theme={theme} />
      </>
    )
  )
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default withRouter(themeConsumer(WelcomePage, 'banner'))
