import React, { useState, useCallback } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Radio, RadioGroup, Modal } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'

import { CoverProps, PeriodData } from '../../../../../types/components/PremiumCalculator'
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { isFeatureEnabled } from '../../../../../utils/featureToggling'
import { getSectionDetails } from '../../../../../utils/premiumCalculator'
import { createEvent } from '../../../../../utils/telemetry'
import styles from './cover.styles'

// components
import Options from '../Options'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'
import { Store } from '../../../../../types/store'

// selectors
import { getConfig } from '../../../../../selectors/common.selectors'

// constants
import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../../../../../constants/policies'
import { NEW_STEPPED_LABEL, PREMIUM_STYLE_STEPPED } from '../../../../../constants/benefit'

const CoverDetails = styled('div')(styles.coverDetails)
const ItemsGroup = styled('div')(styles.itemsGroup)
const Wrapper = styled('div')(styles.wrapper)
const ChildBenefits = styled('div')(styles.childBenefits)
const ExtendedBenefitLabel = styled('div')(styles.extendedBenefitLabel)
const Label = styled('div')(styles.label)
const LifeInsured = styled('div')(styles.lifeInsured)
const SumInsuredLabel = styled('label')(styles.label)
const ChildBenefitsList = styled('ul')(styles.childBenefitsList)
const PremiumContainer = styled('div')(styles.premiumContainer)
const DisplayedPremium = styled('div')(styles.displayedPremium)
const SumInsuredItem = styled('div')(styles.sumInsuredItem)
const Value = styled('p')(styles.value)
const ParentBenefit = styled('div')(styles.parentBenefit)
const RadioGroupWrapper = styled('div')(styles.radioGroupWrapper)
const PeriodModalBtnContainer = styled('div')(styles.periodModalBtnContainer)

const Cover = (props: CoverProps) => {
  const { fields, cover, policy } = props
  const {
    tpdDefinition,
    benefitType,
    benefitName,
    benefitInstanceNo,
    coverStyle,
    waitingPeriodData = [],
    waitingPeriod,
    coverPeriodData = [],
    coverPeriod,
    type,
    isTwoRowLayout,
    newPremiumAmount,
    linkedSGBOBenefits = [],
    existingSumInsured,
    optimiserParentBenefitReference,
    lifeInsured,
  } = cover
  const dispatch = useDispatch()
  const config = useSelector(getConfig)
  const isCaliRelabel = isFeatureEnabled('CaliRelabel', config?.FEATURES)
  const alterationsState = useSelector((state: Store) => state.alterations)
  const [isChildPeriodUpdateModal, setIsChildPeriodUpdateModal] = useState(false)
  const [isBenefitPeriod, setIsBenefitPeriod] = useState(false)
  const [valPeriod, setValPeriod] = useState('')
  const { sumInsuredErrors } = alterationsState
  const sumInsuredErrorMsg =
    sumInsuredErrors[`${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`]

  const resetWaitingBenefitPeriod = useCallback(
    (bType: string, bInstanceNo: string | number, isBPeriod: boolean) => {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.resetWaitingBenefitPeriodData(
          {
            type: bType,
            benefitInstanceNo: bInstanceNo,
          },
          policy?.bancsPolicyNo,
          isBPeriod
        )
      )
    },
    []
  )

  const changeWaitingBenefitPeriod = useCallback(
    (
      bType: string,
      bInstanceNo: string | number,
      bName: string,
      bancsPolicyNo: string,
      isBPeriod: boolean,
      selectedPeriod?: PeriodData
    ) => {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.changeWaitingBenefitPeriod(
          {
            type: bType,
            benefitInstanceNo: bInstanceNo,
            name: bName,
          },
          bancsPolicyNo,
          { value: selectedPeriod?.value, unit: selectedPeriod?.unit },
          isBPeriod
        )
      )
    },
    []
  )

  const handleChangePeriod = (value: string, isBPeriod: boolean) => {
    const selectedPeriod = isBPeriod
      ? coverPeriodData.find(({ label }) => label === value)
      : waitingPeriodData.find(({ label }) => label === value)

    const currentPeriod = isBPeriod ? coverPeriod : waitingPeriod

    if (
      selectedPeriod?.value === currentPeriod?.value &&
      selectedPeriod?.unit === currentPeriod?.unit
    ) {
      resetWaitingBenefitPeriod(type, benefitInstanceNo, isBPeriod)
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(({ type: cbType, benefitInstanceNo: cbInstanceNo }) =>
          resetWaitingBenefitPeriod(cbType, cbInstanceNo, isBPeriod)
        )
    } else {
      changeWaitingBenefitPeriod(
        type,
        benefitInstanceNo,
        benefitName,
        policy?.bancsPolicyNo,
        isBPeriod,
        selectedPeriod
      )
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) =>
            changeWaitingBenefitPeriod(
              cbType,
              cbInstanceNo,
              cbInstanceName,
              policy?.bancsPolicyNo,
              isBPeriod,
              selectedPeriod
            )
        )
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetAlterationsQuoteError())

    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.altsCalculateQuote((err: object) => {
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator fetching new premium',
              error: !!err,
            },
          },
        })
        apiEvent.end()
      })
    )
  }

  const onChangeWaitingBenefitPeriod = (isBPeriod: boolean, value: string) => {
    if (isBPeriod) {
      const event = createEvent({
        GA: {
          category: 'PC-BenefitPeriod',
          action: 'Benefit Period',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Premium calculator benefit period',
          },
        },
      })
      event.end()
    } else {
      const event = createEvent({
        GA: {
          category: 'PC-WaitingPeriod',
          action: 'Waiting Period',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Premium calculator waiting period',
          },
        },
      })
      event.end()
    }
    setIsBenefitPeriod(isBPeriod)
    if (linkedSGBOBenefits.length > 0) {
      // warning modal is shown before executing alteration
      setIsChildPeriodUpdateModal(true)
      setValPeriod(value)
    } else {
      handleChangePeriod(value, isBPeriod)
    }
  }

  // Warning modal for stating change will also impact child benefit
  // Modal is shown for parent benefit only if change will be executed in child benefit also
  // On click of yes, change is executed in benefit and child benefit
  const handleChildPeriodUpdateModalClose = () => {
    setIsChildPeriodUpdateModal(false)
    setIsBenefitPeriod(false)
    setValPeriod('')
  }

  const handleChildPeriodUpdateModalYesClick = () => {
    setIsChildPeriodUpdateModal(false)
    handleChangePeriod(valPeriod, isBenefitPeriod)
  }

  const handleSumInsuredChange = useCallback(({ value }: { value: number }) => {
    // eslint-disable-next-line no-restricted-properties
    if (!window.isNaN(value)) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.alterSumInsured(
          {
            type,
            benefitInstanceNo,
            name: benefitName,
            coverAmount: existingSumInsured,
            optimiserParentBenefitReference,
          },
          value,
          policy.bancsPolicyNo,
          policy.policyInstanceNo,
          true
        )
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.resetAlterationsQuoteError())
      // doing validations on changed sum insured fields and setting error
      dispatch(actionCreators.setSumInsuredErrors(fields))
    }
  }, [])
  const handleSumInsuredBlur = useCallback(() => {
    const event = createEvent({
      GA: {
        category: 'PC-CoverAmount',
        action: 'Cover Amount',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator cover amount',
        },
      },
    })
    event.end()
    // trigger API to fetch new premiums, API call will only occur if
    // there is no front-end errors and alteration is done in the quote
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.altsCalculateQuote((err: object) => {
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator fetching new premium',
              error: !!err,
            },
          },
        })
        apiEvent.end()
      })
    )
  }, [])
  const handleSumInsuredKeyUp = useCallback(
    (event: { key: string; target: { blur: () => void } }) => {
      if (event.key === 'Enter') {
        event.target.blur()
      }
    },
    []
  )

  return (
    <Wrapper>
      {/* two/three coloumns layout excluding commencement date and cover amount column */}
      <CoverDetails isThreeColumnLayout={!!tpdDefinition || !!benefitType}>
        <div>
          <Label>{renderTextField(fields.Instance)}</Label>
          <p>{moment(cover.benefitCommencementDate, 'YYYY-MM-DD').format('DD MMM YYYY')}</p>
          <LifeInsured>
            <div>{renderTextField(fields.LifeInsured)}</div>
            <div>{lifeInsured}</div>
          </LifeInsured>
          {cover.parentBenefitForDisplay && (
            <ParentBenefit>
              <Icon iconName={['far', 'link']} />
              <div>
                {cover.linkedBenefitLabel?.replace('##', cover.parentBenefitForDisplay.name)}
              </div>
            </ParentBenefit>
          )}
          {Boolean(cover.childBenefits?.length) && (
            <ChildBenefits>
              <ExtendedBenefitLabel>
                {renderTextField(fields.ExtendedBenefitText)}
              </ExtendedBenefitLabel>
              <ChildBenefitsList>
                {cover.childBenefits?.map(childBenefit => (
                  <li
                    key={`child-${childBenefit.childPolicyReferenceNo}-${childBenefit.childType}-${childBenefit.childBenefitInstanceNo}`}
                  >
                    {childBenefit.name}
                  </li>
                ))}
              </ChildBenefitsList>
            </ChildBenefits>
          )}
        </div>
        <SumInsuredItem isError={!!sumInsuredErrorMsg}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <SumInsuredLabel
            htmlFor={`sumInsured-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
          >
            {renderTextField(fields.CoverAmount)}
          </SumInsuredLabel>
          <Input
            htmlFor={`sumInsured-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
            name={`sumInsured-${policy.policyNo}-${cover.type}-${cover.benefitInstanceNo}`}
            value={cover.sumInsuredFieldValue}
            prefix="$"
            disabled={cover.isSumInsuredDisabled || policy?.isPolicyChangeDisabled}
            options={{
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
              numeralPositiveOnly: true,
            }}
            changeHandler={handleSumInsuredChange}
            blurHandler={handleSumInsuredBlur}
            error={
              !(cover.isSumInsuredDisabled || policy?.isPolicyChangeDisabled) &&
              !!sumInsuredErrorMsg
            }
            caption={
              !(cover.isSumInsuredDisabled || policy?.isPolicyChangeDisabled) &&
              (sumInsuredErrorMsg || '')
            }
            keyUpHandler={handleSumInsuredKeyUp}
          />
        </SumInsuredItem>
        {isTwoRowLayout ? (
          <div>
            <ItemsGroup isThreeColumnLayout={!!coverStyle}>
              <div>
                <Label>{renderTextField(fields.Type?.fields?.Heading)}</Label>
                <Value>{benefitType}</Value>
              </div>
              {coverStyle && (
                <div>
                  <Label>{renderTextField(fields.Style?.fields?.Heading)}</Label>
                  <Value>{coverStyle}</Value>
                </div>
              )}
              <div>
                <Label>{renderTextField(fields.Structure?.fields?.Heading)}</Label>
                <Value>
                  {isCaliRelabel &&
                  [POLICY_PRODUCT_CODE_SUPER, POLICY_PRODUCT_CODE_NON_SUPER].includes(
                    policy.productId
                  ) &&
                  cover.premiumStyle === PREMIUM_STYLE_STEPPED
                    ? NEW_STEPPED_LABEL
                    : cover.premiumStyle}
                </Value>
              </div>
            </ItemsGroup>
            <ItemsGroup isThreeColumnLayout>
              <div>
                <Label>{renderTextField(fields.WaitingPeriod?.fields?.Heading)}</Label>
                {waitingPeriodData.length > 1 ? (
                  <RadioGroupWrapper>
                    <RadioGroup>
                      {waitingPeriodData.map((waitPeriod, index) => (
                        <Radio
                          text={waitPeriod.label}
                          value={waitPeriod.label}
                          selectorId={`waitingPeriod_${cover.type}`}
                          handleOnChange={(val: string) => onChangeWaitingBenefitPeriod(false, val)}
                          checked={waitPeriod.isSelected}
                          disabled={policy?.isPolicyChangeDisabled}
                          name={`waitingPeriod-${
                            policy.policyNo
                          }-${type}-${+benefitInstanceNo}-${index}`}
                          htmlFor={`waitingPeriod-${
                            policy.policyNo
                          }-${type}-${+benefitInstanceNo}-${index}`}
                        />
                      ))}
                    </RadioGroup>
                  </RadioGroupWrapper>
                ) : (
                  <Value>
                    {waitingPeriod?.value} {waitingPeriod?.unit}
                  </Value>
                )}
              </div>
              <div>
                <Label>{renderTextField(fields.BenefitPeriod?.fields?.Heading)}</Label>
                {coverPeriodData.length > 1 ? (
                  <RadioGroupWrapper>
                    <RadioGroup>
                      {coverPeriodData.map((covPeriod, index) => (
                        <Radio
                          text={covPeriod.label}
                          value={covPeriod.label}
                          selectorId={`benefitPeriod_${cover.type}`}
                          handleOnChange={(val: string) => onChangeWaitingBenefitPeriod(true, val)}
                          checked={covPeriod.isSelected}
                          disabled={policy?.isPolicyChangeDisabled}
                          name={`coverPeriod-${
                            policy.policyNo
                          }-${type}-${+benefitInstanceNo}-${index}`}
                          htmlFor={`coverPeriod-${
                            policy.policyNo
                          }-${type}-${+benefitInstanceNo}-${index}`}
                        />
                      ))}
                    </RadioGroup>
                  </RadioGroupWrapper>
                ) : (
                  <Value>
                    {coverPeriod?.value} {coverPeriod?.unit}
                  </Value>
                )}
              </div>
              <div>
                <Options {...props} />
              </div>
            </ItemsGroup>
          </div>
        ) : (
          <ItemsGroup isThreeColumnLayout={!!tpdDefinition || !!benefitType}>
            {!!tpdDefinition && (
              <div>
                <Label>{renderTextField(fields.Definition?.fields?.Heading)}</Label>
                <Value>{getSectionDetails(fields?.Definition, tpdDefinition)?.headingText}</Value>
              </div>
            )}
            {!!benefitType && (
              <div>
                <Label>{renderTextField(fields.Type?.fields?.Heading)}</Label>
                <Value>{benefitType}</Value>
              </div>
            )}
            <div>
              <Options {...props} />
            </div>
            <div>
              <Label>{renderTextField(fields.Structure?.fields?.Heading)}</Label>
              <Value>
                {isCaliRelabel &&
                [POLICY_PRODUCT_CODE_SUPER, POLICY_PRODUCT_CODE_NON_SUPER].includes(
                  policy.productId
                ) &&
                cover.premiumStyle === PREMIUM_STYLE_STEPPED
                  ? NEW_STEPPED_LABEL
                  : cover.premiumStyle}
              </Value>
            </div>
          </ItemsGroup>
        )}
      </CoverDetails>
      <PremiumContainer>
        <div>
          <div>{renderTextField(fields.ExistingPremium)}</div>
          <DisplayedPremium>
            {cover.premiumAmount} {policy.paymentFrequency?.label}
          </DisplayedPremium>
        </div>
        {!!newPremiumAmount && (
          <div>
            <div>{renderTextField(fields.NewPremium)}</div>
            <DisplayedPremium isNewPremium>
              {newPremiumAmount} {policy.updatedPaymentFrequency?.label}
            </DisplayedPremium>
          </div>
        )}
      </PremiumContainer>
      <Modal
        isOpen={isChildPeriodUpdateModal}
        onClose={handleChildPeriodUpdateModalClose}
        title={fields.ChildOptionModalHeading}
        footer={
          <PeriodModalBtnContainer>
            <Button onClick={handleChildPeriodUpdateModalYesClick}>
              {renderTextField(fields.ChildOptionModalYesBtn)}
            </Button>
            <Button variant="link" onClick={handleChildPeriodUpdateModalClose}>
              {renderTextField(fields.ChildOptionModalNoBtn)}
            </Button>
          </PeriodModalBtnContainer>
        }
      >
        {renderTextField(fields.ChildOptionModalDescription)}
      </Modal>
    </Wrapper>
  )
}

export default Cover
