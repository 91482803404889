// @flow
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  Radio,
  RadioGroup,
  ToggleGroup,
  Select,
  Input,
  Checkbox,
  Button,
  Heading,
  Notification,
  PageWrap,
  Loader,
} from '@mlcl-digital/mlcl-design'
import DatePickerComponent from '@mlcl-digital/mlcl-design/lib/base/Date'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

// Redux
// @ts-expect-error non-ts code
import { actionCreators } from '../../../actions'

// Components
// @ts-expect-error non-ts code
import Header from '../../molecules/PageHeader'
// @ts-expect-error non-ts code
import { CustomerPortalLifeCycleComponent } from '../../molecules/CustomerPortalLifeCycle'
// @ts-expect-error non-ts code
import AddressLookUp from '../../molecules/AddressLookUp'
import InputPhone from '../../molecules/InputPhone'
// @ts-expect-error non-ts code
import Link from '../../atoms/Link'

// Constants
import { THEME_LIGHT } from '../../../constants/themes'
import { TITLES, STATES, FORM_LABELS, INDEFINITE } from '../../../constants/forms'

// Form
import SCHEMA, { FORM_ID, addressFields, secondaryAddressFields } from './forms.schema'

// Utils
import { renderTextField, reduceAuthorableFields } from '../../../utils/sitecoreUtils'
// @ts-expect-error non-ts code
import history from '../../../utils/browserHistory'
import { createEvent } from '../../../utils/telemetry'

import styles from './addAuthority.styles'

// Types
import { Store } from '../../../types/store'
import {
  AddressChangeReturnValue,
  ChangeReturnValue,
  SelectReturnValue,
} from '../../../types/inputs'
import { SitecoreField } from '../../../types/sitecore'
import { FormFields } from '../../../types/forms'
import {
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../../../constants/policies'

const durationToggleValues = ['indefinitely', 'endDate']
const formToggleValues = ['individual', 'business']
const buttonLabels = ['Cancel', 'Add Authority']

const Wrap = styled(PageWrap)(styles.wrap)
const FormSection = styled('div')(styles.formSection)
const InputSubtitle = styled('div')(styles.inputSubtitle)
const Divider = styled('div')(styles.divider)
const StyledDatePicker = styled('div')(styles.datePicker)
const FieldGroup = styled('div')(styles.fieldGroup)
const CheckboxWrapper = styled('div')(styles.checkBox)
const ButtonBar = styled('div')(styles.buttons)
const ToggleWrapper = styled('div')(styles.toggleWrapper)
const AddressHeading = styled('div')(styles.addressHeading)

const parseDate = (date: Date) => {
  const dateBits = date
    .toLocaleString('en-AU', { day: '2-digit', month: '2-digit', year: 'numeric' })
    .split('/')
  return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`
}

type Props = {
  fields: {
    heading: SitecoreField
    confirmationDisclaimer: SitecoreField
    endDateDisclaimer: SitecoreField
    individualHeading: SitecoreField
    businessHeading: SitecoreField
    durationHeading: SitecoreField
    individualToggleLabel: SitecoreField
    businessToggleLabel: SitecoreField
    addAuthoritySubmitError: SitecoreField
  }
}

const AddAuthority = (props: Props) => {
  const earliestDateAllowed = moment().add(1, 'days').toDate()
  const [indefinitely, endDate] = durationToggleValues
  const [individual, business] = formToggleValues
  const [cancelLabel, addAuthorityLabel] = buttonLabels
  const dispatch = useDispatch()
  const [durationToggle, setDurationToggle] = useState(indefinitely)
  const [datePicker, setDatePicker] = useState(earliestDateAllowed)
  const [formToggle, setFormToggle] = useState(individual)
  const [confirmAuthorityRequest, setConfirmAuthorityRequest] = useState(false)
  const [showFailedNotificationMessage, toggleFailedNotificationMessage] = useState(false)
  const [hasSecondaryAddress, setSecondaryAddress] = useState(false)
  const formData = useSelector((state: Store) => state.forms)[FORM_ID]
  const bancsCustomerNo = useSelector((state: Store) => state.okta.bancsCustomerNo)
  const isLoading = useSelector((state: Store) => state.letterOfAuthority.isLoading)
  const hasFailed = useSelector((state: Store) => state.letterOfAuthority.hasError)
  const masterList = useSelector((state: Store) => state.masterList)

  useEffect(() => {
    const schema = SCHEMA(formToggle === individual)
    const { formInit, resetLOA } = actionCreators
    dispatch(formInit(FORM_ID, schema, { duration: INDEFINITE }))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(resetLOA())
    const event = createEvent({
      GA: {
        category: 'Add authority',
        action: ' select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Add authority',
        },
      },
    })
    event.end()
  }, [])

  useEffect(() => {
    if (hasFailed) {
      toggleFailedNotificationMessage(true)
    }
  }, [hasFailed])

  const handleDurationToggleUpdate = (value: string) => {
    const { formUpdateField } = actionCreators
    dispatch(
      formUpdateField(FORM_ID, 'duration', {
        value: value === indefinitely ? INDEFINITE : parseDate(datePicker),
        error: false,
      })
    )
    setDurationToggle(value)
  }

  const handleDurationDateChange = (date: Date) => {
    const { formUpdateField } = actionCreators
    dispatch(
      formUpdateField(FORM_ID, 'duration', {
        error: false,
        value: parseDate(date),
      })
    )
    setDatePicker(date)
  }

  const handleFormToggleChange = ({ value }: ChangeReturnValue) => {
    setFormToggle(value)
    setSecondaryAddress(false)
    const schema = SCHEMA(value === individual)
    const { formInit } = actionCreators
    const {
      fields: { duration },
    } = formData
    dispatch(formInit(FORM_ID, schema, { duration: duration.value }))
  }

  const handleInputChange = ({ value, name }: ChangeReturnValue) => {
    const { formUpdateField } = actionCreators
    dispatch(formUpdateField(FORM_ID, name, { error: false, value }))
  }

  const handleSelectChange = ({ value, name }: SelectReturnValue) => {
    const { formUpdateField } = actionCreators
    dispatch(formUpdateField(FORM_ID, name, { error: false, value: value.value }))
  }

  const handleConfirmCheck = ({ value }: ChangeReturnValue) => {
    setConfirmAuthorityRequest(!!value)
  }

  const handleAutomaticAddressChange = (address: AddressChangeReturnValue) => {
    const { formUpdate } = actionCreators
    const { data, name, value } = address
    const formFields = Object.keys(data).reduce(
      (fields, key) => ({
        ...fields,
        [key]: {
          value: data[key],
          error: false,
        },
      }),
      {
        [name]: {
          value,
          error: false,
        },
      }
    )
    dispatch(formUpdate(FORM_ID, formFields))
  }

  const handleSecondaryAddressToggle = ({ value }: ChangeReturnValue) => {
    const isSet = !value
    setSecondaryAddress(isSet)
    const schema = SCHEMA(formToggle === individual, isSet)
    const { formInit } = actionCreators
    dispatch(
      formInit(
        FORM_ID,
        schema,
        Object.keys(formData.fields).reduce(
          (fields, key) => ({
            ...fields,
            [key]: formData.fields[key].value,
          }),
          {}
        )
      )
    )
  }

  const handleFormSubmit = () => {
    const schema = SCHEMA(formToggle === individual, hasSecondaryAddress)
    const { formSubmit, formValidate, handleLOACreate } = actionCreators
    dispatch(formValidate(FORM_ID, schema))
    dispatch(
      formSubmit(FORM_ID, schema, (data: FormFields) => {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          handleLOACreate(
            {
              ...data,
              bancsCustomerNo,
              partyType:
                formToggle === individual
                  ? POLICY_PARTY_TYPE_INDIVIDUAL
                  : POLICY_PARTY_TYPE_BUSINESS,
            },
            (success: boolean) => {
              if (success) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                history.push('/customerPersonalDetails')
              }
            },
            masterList
          )
        )
      })
    )
  }

  const handleAddressToggle = (isManual: boolean, name: string) => {
    const { formUpdate } = actionCreators
    const refreshedAddressFields = Object.keys(
      name === 'address' ? addressFields : secondaryAddressFields
    ).reduce(
      (fields, key) => ({
        ...fields,
        [key]: {
          value: '',
          error: false,
        },
      }),
      {}
    )
    dispatch(formUpdate(FORM_ID, refreshedAddressFields))
  }

  if (!formData) return null

  if (formToggle === individual && !formData.fields.title) return null

  const {
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    email,
    mobilePhone,
    postalNumber,
    country,
    houseNo,
    locality,
    postCode,
    state,
    street,
    address,
    phoneCode,
    abn,
    companyName,
    postalAddress,
    postalPostalNumber,
    postalCountry,
    postalHouseNo,
    postalLocality,
    postalPostCode,
    postalState,
    postalStreet,
  } = formData.fields

  const { fields } = props
  const { confirmationDisclaimer, individualToggleLabel, businessToggleLabel } =
    reduceAuthorableFields(fields) as {
      confirmationDisclaimer: string
      individualToggleLabel: string
      businessToggleLabel: string
    }

  return (
    <>
      <Header slim theme={THEME_LIGHT} heading={fields.heading} />
      <CustomerPortalLifeCycleComponent>
        <Wrap>
          <FormSection>
            <Heading variant="h2" size="medium">
              {renderTextField(fields.durationHeading)}
            </Heading>
            <RadioGroup type="inline">
              <Radio
                text="Indefinitely"
                checked={durationToggle === indefinitely}
                htmlFor="indefinitely"
                name="indefinitely"
                value={indefinitely}
                handleOnChange={handleDurationToggleUpdate}
              />
              <Radio
                text="Choose an end date"
                checked={durationToggle === endDate}
                htmlFor="endDate"
                name="endDate"
                value={endDate}
                handleOnChange={handleDurationToggleUpdate}
              />
            </RadioGroup>
            {durationToggle === endDate && (
              <div>
                <StyledDatePicker data-testId="date-picker">
                  <DatePickerComponent
                    onChange={handleDurationDateChange}
                    selected={datePicker}
                    dateFormat="dd/MM/yyyy"
                    inline={false}
                    minDate={earliestDateAllowed}
                  />
                </StyledDatePicker>
              </div>
            )}
            <InputSubtitle>{renderTextField(fields.endDateDisclaimer)}</InputSubtitle>
          </FormSection>
          <Divider />
          <ToggleWrapper>
            <ToggleGroup
              labelSize="sm"
              htmlFor="searchType"
              variant="tab"
              name="searchType"
              value={formToggle}
              label="Who are you adding?"
              labelledby="searchType"
              options={[
                {
                  label: individualToggleLabel,
                  value: individual,
                  shortLabel: 'K',
                },
                {
                  label: businessToggleLabel,
                  value: business,
                  shortLabel: 'R',
                },
              ]}
              handleChange={handleFormToggleChange}
            />
          </ToggleWrapper>

          <FormSection>
            <Heading variant="h2" size="medium">
              {renderTextField(
                formToggle === individual ? fields.individualHeading : fields.businessHeading
              )}
            </Heading>
            {formToggle === individual && (
              <>
                <FieldGroup>
                  <Select
                    options={TITLES}
                    changeHandler={handleInputChange}
                    value={title.value}
                    name="title"
                    label={FORM_LABELS.title}
                    placeholder={FORM_LABELS.title}
                    error={title.error.error}
                    caption={title.error.error && title.error.errorMsg}
                    htmlFor="title"
                  />
                  <Input
                    type="text"
                    placeholder={FORM_LABELS.firstName}
                    label={FORM_LABELS.firstName}
                    value={firstName.value}
                    name="firstName"
                    changeHandler={handleInputChange}
                    error={firstName.error.error}
                    caption={firstName.error.error && firstName.error.errorMsg}
                    htmlFor="firstName"
                  />
                  <Input
                    type="text"
                    placeholder={FORM_LABELS.middleName}
                    label={FORM_LABELS.middleName}
                    value={middleName.value}
                    name="middleName"
                    changeHandler={handleInputChange}
                    htmlFor="middleName"
                  />
                  <Input
                    type="text"
                    placeholder={FORM_LABELS.lastName}
                    value={lastName.value}
                    name="lastName"
                    changeHandler={handleInputChange}
                    label={FORM_LABELS.lastName}
                    error={lastName.error.error}
                    caption={lastName.error.error && lastName.error.errorMsg}
                    htmlFor="lastName"
                  />
                  <Input
                    type="text"
                    placeholder="DD/MM/YYYY"
                    label={FORM_LABELS.dateOfBirth}
                    options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                    value={dateOfBirth.value}
                    name="dateOfBirth"
                    changeHandler={handleInputChange}
                    error={dateOfBirth.error.error}
                    caption={dateOfBirth.error.error && dateOfBirth.error.errorMsg}
                    htmlFor="dateOfBirth"
                  />
                </FieldGroup>
                <FieldGroup>
                  <Input
                    type="text"
                    placeholder={FORM_LABELS.email}
                    label={FORM_LABELS.email}
                    value={email.value}
                    name="email"
                    changeHandler={handleInputChange}
                    error={email.error.error}
                    caption={email.error.error && email.error.errorMsg}
                    htmlFor="email"
                  />
                  <InputPhone
                    selectPhoneCode={{
                      placeholder: 'IDC',
                      label: FORM_LABELS.idc,
                      value: phoneCode.value,
                      name: 'phoneCode',
                      changeHandler: handleSelectChange,
                      error: phoneCode.error.error,
                      caption: phoneCode.error.error && phoneCode.error.errorMsg,
                    }}
                    inputPhoneNumber={{
                      placeholder: FORM_LABELS.mobilePhone,
                      label: FORM_LABELS.mobilePhone,
                      value: mobilePhone.value,
                      name: 'mobilePhone',
                      changeHandler: handleInputChange,
                      error: mobilePhone.error.error,
                      caption: mobilePhone.error.error && mobilePhone.error.errorMsg,
                    }}
                  />
                </FieldGroup>
              </>
            )}
            {formToggle === business && (
              <>
                <FieldGroup>
                  <Input
                    type="text"
                    placeholder={FORM_LABELS.companyName}
                    label={FORM_LABELS.companyName}
                    value={companyName.value}
                    name="companyName"
                    changeHandler={handleInputChange}
                    error={companyName.error.error}
                    caption={companyName.error.error && companyName.error.errorMsg}
                    htmlFor="companyName"
                  />
                  <Input
                    type="text"
                    placeholder={FORM_LABELS.abn}
                    label={FORM_LABELS.abn}
                    value={abn.value}
                    name="abn"
                    changeHandler={handleInputChange}
                    error={abn.error.error}
                    caption={abn.error.error && abn.error.errorMsg}
                    htmlFor="abn"
                  />
                </FieldGroup>
              </>
            )}
            <FieldGroup>
              <AddressHeading>Address</AddressHeading>
            </FieldGroup>
            <AddressLookUp
              name="address"
              auto={{
                label:
                  formToggle === business
                    ? FORM_LABELS.companyAddress
                    : FORM_LABELS.residentialAddress,
                placeholder: 'Start typing address...',
                toggleLabel: FORM_LABELS.cantFindAddress,
                value: address.value,
                selectChangeHandler: handleAutomaticAddressChange,
                addressError: street.error.error,
                addressName: 'address',
                addressErrorMessage: 'Please enter an address',
              }}
              manual={{
                streetPlaceholder: FORM_LABELS.street,
                streetValue: street.value,
                streetError: street.error.error,
                streetRequiredErrorMessage: street.error.errorMsg,
                houseNoPlaceholder: FORM_LABELS.houseNo,
                houseNoValue: houseNo.value,
                postOfficeNoPlaceholder: FORM_LABELS.poBox,
                postOfficeNoName: 'postalNumber',
                postOfficeNoValue: postalNumber.value,
                localityPlaceholder: FORM_LABELS.locality,
                localityLabelValue: locality.value,
                localityError: locality.error.error,
                localityRequiredErrorMessage: locality.error.errorMsg,
                statePlaceholder: FORM_LABELS.state,
                stateOptions: STATES,
                stateValue: state.value,
                stateError: state.error.error,
                stateRequiredErrorMessage: state.error.errorMsg,
                countryPlaceholder: FORM_LABELS.country,
                countryValue: country.value,
                countryError: country.error.error,
                countryRequiredErrorMessage: country.error.errorMsg,
                postCodePlaceholder: FORM_LABELS.postCode,
                postCodeValue: postCode.value,
                postCodeError: postCode.error.error,
                postCodeRequiredErrorMessage: postCode.error.errorMsg,
                manualToggleLabel: 'Address lookup',
                inputEntryHandler: handleInputChange,
              }}
              toggleHandler={handleAddressToggle}
            />

            <CheckboxWrapper>
              <Checkbox
                onChangeHandler={handleSecondaryAddressToggle}
                checked={!hasSecondaryAddress}
                text={FORM_LABELS.postalAddressToggle}
                name="postalAddressToggle"
                htmlFor="postalAddressToggle"
                value="yes"
              />
            </CheckboxWrapper>

            {hasSecondaryAddress && (
              <AddressLookUp
                name="postalAddress"
                auto={{
                  label: FORM_LABELS.postalAddress,
                  placeholder: 'Start typing address...',
                  toggleLabel: FORM_LABELS.cantFindAddress,
                  value: postalAddress.value,
                  selectChangeHandler: handleAutomaticAddressChange,
                  addressError: postalStreet.error.error,
                  addressName: 'postalAddress',
                  addressErrorMessage: 'Please enter an address',
                }}
                manual={{
                  streetPlaceholder: FORM_LABELS.street,
                  streetValue: postalStreet.value,
                  streetError: postalStreet.error.error,
                  streetRequiredErrorMessage: postalStreet.error.errorMsg,
                  streetName: 'postalStreet',
                  houseNoPlaceholder: FORM_LABELS.houseNo,
                  houseNoValue: postalHouseNo.value,
                  houseNoName: 'postalHouseNo',
                  postOfficeNoPlaceholder: FORM_LABELS.poBox,
                  postOfficeNoName: 'postalPostalNumber',
                  postOfficeNoValue: postalPostalNumber.value,
                  localityPlaceholder: FORM_LABELS.locality,
                  localityLabelValue: postalLocality.value,
                  localityError: postalLocality.error.error,
                  localityRequiredErrorMessage: postalLocality.error.errorMsg,
                  localityName: 'postalLocality',
                  statePlaceholder: FORM_LABELS.state,
                  stateOptions: STATES,
                  stateValue: postalState.value,
                  stateError: postalState.error.error,
                  stateName: 'postalState',
                  stateRequiredErrorMessage: postalState.error.errorMsg,
                  countryPlaceholder: FORM_LABELS.country,
                  countryValue: postalCountry.value,
                  countryError: postalCountry.error.error,
                  countryRequiredErrorMessage: postalCountry.error.errorMsg,
                  countryName: 'postalCountry',
                  postCodePlaceholder: FORM_LABELS.postCode,
                  postCodeValue: postalPostCode.value,
                  postCodeError: postalPostCode.error.error,
                  postCodeRequiredErrorMessage: postalPostCode.error.errorMsg,
                  postCodeName: 'postalPostCode',
                  manualToggleLabel: 'Address lookup',
                  postalType: 'postalPostalType',
                  postalNumber: 'postalPostalNumber',
                  inputEntryHandler: handleInputChange,
                }}
                toggleHandler={handleAddressToggle}
              />
            )}
            <CheckboxWrapper>
              <Checkbox
                onChangeHandler={handleConfirmCheck}
                checked={confirmAuthorityRequest}
                text={confirmationDisclaimer}
                name="confirmationCheckbox"
                htmlFor="confirmationCheckbox"
                value="yes"
              />
            </CheckboxWrapper>
            <ButtonBar>
              <Button
                onClick={handleFormSubmit}
                disabled={!confirmAuthorityRequest}
                variant="primary"
                size="small"
              >
                {addAuthorityLabel}
              </Button>
              <Link variant="link" to="/customerPersonalDetails">
                {cancelLabel}
              </Link>
              {isLoading && <Loader />}
            </ButtonBar>
          </FormSection>
          {showFailedNotificationMessage && (
            <Notification
              variant="error"
              closeHandler={() => toggleFailedNotificationMessage(false)}
            >
              {renderTextField(fields.addAuthoritySubmitError)}
            </Notification>
          )}
        </Wrap>
      </CustomerPortalLifeCycleComponent>
    </>
  )
}

export default AddAuthority
