import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontFamily, fontSize, mediaQueries } =
  Variables as VariablesType

export default {
  pageContainer: {
    margin: '0 auto',
    maxWidth: space(150, true),
    padding: `${space(5, true)} ${space(3, true)}`,
  },
  inflationProofingDetails: {
    width: '100%',
    [mediaQueries.lg]: {
      maxWidth: '51%',
    },
    marginBottom: space(5, true),
  },
  description: {
    color: colours.tertiaryOne,
    marginBottom: space(3),
  },
  tooltipContainer: {
    display: 'inline-block',
    marginLeft: space(0.5),
    '> div': {
      maxWidth: space(26, true),
      fontWeight: fontWeights.light,
      fontFamily: fontFamily.sourceSans,
    },
  },
  policiesContainer: {
    marginTop: space(5),
    [mediaQueries.lg]: {
      marginTop: 0,
    },
    '> p': {
      [mediaQueries.lg]: {
        display: 'none',
      },
    }
  },
  notification: {
    margin: `${space(3, true)} 0`,
    padding: space(2, true),
    display: 'flex',
    alignItems: 'center',
    border: 0,
    svg: {
      fontSize: fontSize.md,
    },
    span: {
      marginLeft: space(1),
      fontWeight: fontWeights.semiBold,
    },
  },
  styleOverrides: {
    headerCell: {
      '&:last-of-type': {
        minWidth: space(30),
        [mediaQueries.lg]: {
          minWidth: '25%',
        },
      },
    },
    cell: {
      '&:last-of-type': {
        minWidth: space(30),
        [mediaQueries.lg]: {
          minWidth: '25%',
        },
        div: {
          flexGrow: '0.75',
        },
      },
    },
  },
}
