import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'

// components
import { Heading, Notification } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import SectionWrapper from '../SectionWrapper'
import SidePanel from '../SidePanel'
import CoverGroups from './CoverGroups'
// @ts-expect-error file not in typescript
import WithLoader from '../../../molecules/WithLoader'

// types
import { PremiumCalculatorQuoteProps } from '../../../../types/components/PremiumCalculator'
import { Store } from '../../../../types/store'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { fetchExplainerTextData, getSectionDetails } from '../../../../utils/premiumCalculator'
// @ts-expect-error file not in typescript
import history from '../../../../utils/browserHistory'
import { createEvent } from '../../../../utils/telemetry'
// @ts-expect-error file not in typescript
import { isFeatureEnabled } from '../../../../utils/featureToggling'

// selectors
import {
  getPremiumCalculateQuoteData,
  getAnniversaryBannerMessages,
  isErrorOnAlteredQuote,
} from '../../../../selectors/premiumCalculatorQuote'
// @ts-expect-error file not in typescript
import { getIsAnyPolicyAltered } from '../../../../selectors/createQuote'
import { getConfig } from '../../../../selectors/common.selectors'

// constants
import { PREMIUM_CALCULATOR_LANDING_PAGE } from '../../../../constants/routes'
import { NEW_STEPPED_LABEL, PREMIUM_STYLE_STEPPED } from '../../../../constants/benefit'
import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../../../../constants/policies'

// styles
import styles from './premiumCalculatorQuote.styles'

const Wrapper = styled('div')(styles.wrapper)
const PanelHeading = styled('div')(styles.panelHeading)
const DetailsSection = styled('div')(styles.detailsSection)
const ResetButton = styled(Button)(styles.resetButton)
const ButtonContainer = styled('div')(styles.buttonContainer)
const StyledNotification = styled(Notification)(styles.notification)

const PremiumCalculatorQuote = ({ fields }: PremiumCalculatorQuoteProps) => {
  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'PC-Calculatorpageload',
        action: 'Calculator page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator calculator page load',
        },
      },
    })
    event.end()
  }, [])

  const isAnyPolicyAltered = useSelector(getIsAnyPolicyAltered)
  const anniversaryBannerMessages = useSelector((state: Store) =>
    getAnniversaryBannerMessages(state, fields)
  )
  const isErrorOnQuote = useSelector(isErrorOnAlteredQuote)
  const premiumCalculateQuoteData = useSelector((state: Store) =>
    getPremiumCalculateQuoteData(state, fields)
  )
  const { isFetchingCalculateQuote, isAltsQuoteDownloaded } = useSelector(
    (state: Store) => state.alterations
  )
  const explainerText = fetchExplainerTextData(premiumCalculateQuoteData)
  const config = useSelector(getConfig)
  const isCaliRelabel = isFeatureEnabled('CaliRelabel', config?.FEATURES)

  const dispatch = useDispatch()

  const handleBackClick = () => {
    const event = createEvent({
      GA: {
        category: 'PC-Back-calcscreen',
        action: 'Back - calc screen',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator back calculator screen',
        },
      },
    })
    event.end()
    if (isAnyPolicyAltered && !isAltsQuoteDownloaded) {
      dispatch(actionCreators.setIsConfirmLeaveModal(true, PREMIUM_CALCULATOR_LANDING_PAGE))
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      history.goBack()
    }
  }

  const handleReset = () => {
    const event = createEvent({
      GA: {
        category: 'PC-Reset',
        action: 'Reset',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator reset',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.deleteAlterationsInQuote())
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetSumInsuredErrors())
  }

  const handleContinue = () => {
    const event = createEvent({
      GA: {
        category: 'PC-Continue',
        action: 'Continue',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator continue',
        },
      },
    })
    event.end()
    dispatch(actionCreators.timelineNextState())
  }

  // relabel only apply to mlcl policy either super or non super
  const isMLCLPolicy = (
    calculatorQuoteData: { coverGroup: { policy: { productId: string } }[] }[]
  ) =>
    calculatorQuoteData?.find((quoteData: { coverGroup: { policy: { productId: string } }[] }) =>
      quoteData.coverGroup?.find((i: { policy: { productId: string } }) =>
        [POLICY_PRODUCT_CODE_SUPER, POLICY_PRODUCT_CODE_NON_SUPER].includes(i?.policy?.productId)
      )
    )

  return (
    <WithLoader
      isLoading={isFetchingCalculateQuote}
      overlay
      loaderProps={{ loaderContent: fields.LoaderLabel?.value }}
    >
      <Wrapper>
        <p>{renderTextField(fields.Description, true)}</p>
        {anniversaryBannerMessages.length > 0 && (
          <StyledNotification variant="info">
            <Icon iconName={['far', 'circle-info']} />
            <div>
              {anniversaryBannerMessages.map(({ policyNo, bannerMessage }) => (
                <p>
                  <span>{policyNo}: </span>
                  {bannerMessage}
                </p>
              ))}
            </div>
          </StyledNotification>
        )}
      </Wrapper>
      <SectionWrapper>
        <div>
          <CoverGroups fields={fields} premiumCalculateQuoteData={premiumCalculateQuoteData} />
          <ButtonContainer>
            <Button variant="secondaryWithTheme" onClick={handleBackClick}>
              {renderTextField(fields.BackButton)}
            </Button>
            <ResetButton
              variant="secondaryWithTheme"
              disabled={!isAnyPolicyAltered}
              onClick={handleReset}
            >
              {renderTextField(fields.Reset)}
            </ResetButton>
            <Button disabled={isErrorOnQuote} onClick={handleContinue}>
              {renderTextField(fields.ContinueButton)}
            </Button>
          </ButtonContainer>
        </div>
        <SidePanel heading={fields?.UnderstandTermsHeading?.value}>
          <PanelHeading>{renderTextField(fields.AllInsurance)}</PanelHeading>
          <DetailsSection>
            <Heading variant="h5">{renderTextField(fields.Instance)}</Heading>
            <p>{renderTextField(fields?.StartDateDescription, true)}</p>
            <Heading variant="h5">{renderTextField(fields?.Structure?.fields?.Heading)}</Heading>
            {explainerText.premiumStyle.map(style => {
              const premiumStyleDetails = getSectionDetails(fields?.Structure, style)
              return (
                <>
                  <span>
                    {isCaliRelabel &&
                    isMLCLPolicy(premiumCalculateQuoteData) &&
                    premiumStyleDetails?.headingText === PREMIUM_STYLE_STEPPED
                      ? NEW_STEPPED_LABEL
                      : premiumStyleDetails?.headingText}
                  </span>
                  <p>{renderTextField(premiumStyleDetails?.description, true)}</p>
                </>
              )
            })}
          </DetailsSection>
          {explainerText.coversExplainerText.map(coverExplainerText => (
            <>
              <PanelHeading>{coverExplainerText.coverGroupName}</PanelHeading>
              <DetailsSection>
                <p>
                  {renderTextField(
                    getSectionDetails(fields?.Cover, coverExplainerText?.benefitTypes[0]?.type)
                      ?.description,
                    true
                  )}
                </p>
                {fields?.Type.fields.Data.some(
                  coverTypes =>
                    coverTypes.fields.Code.value === coverExplainerText?.benefitTypes[0].type
                ) && (
                  <>
                    <Heading variant="h5">{renderTextField(fields?.Type?.fields?.Heading)}</Heading>
                    {coverExplainerText.benefitTypes.map(typeDetails => (
                      <>
                        <span>{typeDetails?.heading}</span>
                        <p>{renderTextField(typeDetails?.description, true)}</p>
                      </>
                    ))}
                  </>
                )}
                {coverExplainerText.tpdDefinition.length > 0 && (
                  <>
                    <Heading variant="h5">
                      {renderTextField(fields?.Definition?.fields?.Heading)}
                    </Heading>
                    {coverExplainerText.tpdDefinition.map(definition => {
                      const definitionDetails = getSectionDetails(fields?.Definition, definition)
                      return (
                        <>
                          <span>{definitionDetails?.headingText}</span>
                          <p>{renderTextField(definitionDetails.description, true)}</p>
                        </>
                      )
                    })}
                  </>
                )}
                {!coverExplainerText.hideOptionsText && (
                  <>
                    {coverExplainerText.coverStyle.length > 0 && (
                      <>
                        <Heading variant="h5">
                          {renderTextField(fields?.Style?.fields?.Heading)}
                        </Heading>
                        {coverExplainerText.coverStyle.map(style => {
                          const coverStyleDetails = getSectionDetails(fields?.Style, style)
                          return (
                            <>
                              <span>{coverStyleDetails?.headingText}</span>
                              <p>{renderTextField(coverStyleDetails?.description, true)}</p>
                            </>
                          )
                        })}
                      </>
                    )}
                    {coverExplainerText.showWaitingPeriod && (
                      <>
                        <Heading variant="h5">
                          {renderTextField(fields?.WaitingPeriod?.fields?.Heading)}
                        </Heading>
                        <p>
                          {renderTextField(
                            getSectionDetails(fields?.WaitingPeriod, 'waitingPeriod')?.description,
                            true
                          )}
                        </p>
                      </>
                    )}
                    {coverExplainerText.showBenefitPeriod && (
                      <>
                        <Heading variant="h5">
                          {renderTextField(fields?.BenefitPeriod?.fields?.Heading)}
                        </Heading>
                        <p>
                          {renderTextField(
                            getSectionDetails(fields?.BenefitPeriod, 'coverPeriod')?.description,
                            true
                          )}
                        </p>
                      </>
                    )}
                    {coverExplainerText.featuresList.length > 0 && (
                      <>
                        <Heading variant="h5">
                          {renderTextField(fields?.Options?.fields?.Heading)}
                        </Heading>
                        {coverExplainerText.featuresList.map(feature => (
                          <>
                            <span>{feature.displayedFeatureName}</span>
                            <p>{renderTextField(feature.description, true)}</p>
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
              </DetailsSection>
            </>
          ))}
        </SidePanel>
      </SectionWrapper>
    </WithLoader>
  )
}

export default PremiumCalculatorQuote
